import {withPrefix} from 'gatsby';
import React from 'react';

import {Button} from '../../../components/button';
import {Layout} from '../../../components/layout';

const locale = 'en';

export default () => (
    <Layout
        title="Privacy, AVG and data processors"
        description="At Label305 we put the user first. That is why we consider the privacy of end users of our software products extra important."
        ogImage="privacy-en.png"
        translatedPagePath="/nl/voorwaarden/privacy/"
        locale={locale}>
        <section className="section">
            <article className="content content--no-hero">
                <div className="container">
                    <div className="content__display">
                        <h2>Privacy statement</h2>
                        <div className="display__number">P</div>
                    </div>
                    <div className="content__copy">
                        <p className="p-lead">
                            At Label305 we put the end user first. So end users’ privacy takes a particu&shy;larly high
                            priority.
                        </p>
                        <p>
                            Our privacy statement provides a <span className="highlight">clear explanation</span> of how
                            we deal with personal data belonging to our clients and their end users.
                        </p>
                        <p>
                            So if we’re so privacy-aware, why doesn’t this website have a cookie statement? Well, that’s
                            because this website <span className="highlight">doesn’t store</span> any cookies to track
                            you or record visitor statistics. We just don’t think it’s necessary.
                        </p>
                    </div>

                    <div className=" content__buttons content__buttons--50">
                        <Button
                            direct
                            url={withPrefix('/files/privacyverklaring.pdf')}
                            name="Privacy statement (PDF in Dutch)"
                            className="button--download"
                            title="Download our Privacy statement as a PDF (Dutch)"
                        />
                    </div>
                </div>
            </article>

            <article className="content content--no-hero">
                <div className="container">
                    <div className="content__display">
                        <h2>Privacy legislation</h2>
                        <div className="display__number">PL</div>
                    </div>
                    <div className="content__copy">
                        <p className="p-lead">
                            The European privacy legislation (the GDPR) obliges businesses that collect personal data to
                            disclose which external parties process these data.
                        </p>
                        <p>
                            In our <span className="highlight">data processing agreement</span> we make clear
                            arrange&shy;ments with our clients about exactly how we deal with personal data from any
                            software product that we develop for them. Our clients need this agreement to comply with
                            the privacy legislation.
                        </p>
                        <p>
                            We provide a list of all our data{' '}
                            <span className="highlight">processors and sub-processors</span> below. Our ‘data
                            processors’ are external parties whom we only allow to process project files and data
                            concerning our clients. ‘Sub-processors’ are external parties whom we also allow to process
                            data from clients’ software products. These data sometimes include end users’ personal data.
                        </p>
                    </div>

                    <div className=" content__buttons content__buttons--50">
                        <Button
                            direct
                            url={withPrefix('/files/verwerkersovereenkomst.pdf')}
                            name="Data processing agreement (PDF in Dutch)"
                            className="button--download"
                            title="Download our Processing Agreement as a PDF (Dutch)"
                        />
                        <Button
                            direct
                            url={withPrefix('/files/verwerkers.pdf')}
                            name="List of data processors and sub-processors (PDF in Dutch)"
                            className="button--download"
                            title="Download a list of our (sub) processors as PDF (Dutch)"
                        />
                    </div>
                </div>
            </article>
        </section>
    </Layout>
);
